import React, { Component } from "react";
import Container from "../../components/container";
import Nav from "../../components/nav";
import Footer from "../../components/footer";
import WorkCard from "../../components/workcard";

import header from "../../images/work/header.jpg";
import second from "../../images/work/second.jpg";
import persona from "../../images/work/persona.jpg";
import empathymap from "../../images/work/empathymap.jpg";
import grouping from "../../images/work/grouping.jpg";
import userflow from "../../images/work/userflow.jpg";
import third from "../../images/work/third.jpg";
import four from "../../images/work/four.jpg";
import five from "../../images/work/five.jpg";
import six from "../../images/work/six.jpg";
import seven from "../../images/work/seven.jpg";
import eight from "../../images/work/eight.jpg";
import nine from "../../images/work/nine.jpg";

import "../../styles/work.scss";

export default class MaeCookbook extends Component {
  render() {
    return (
      <main className="dark">
        <Nav />
        <Container>
          <div className="work">
            <div className="work__header">
              <h1>UI/UX Case Study: Designing Mae’s Cookbook</h1>
              <div className="divider">
                <h6> Product Brief</h6>
              </div>
              <div className="role-timeline">
                <div className="role">
                  <h6>Role</h6>
                  <p>UI/UX DESIGNER</p>
                  <p>UX RESEARCHER</p>
                </div>
                <div className="timeline">
                  <h6>Timeline</h6>
                  <p>3 weeks</p>
                </div>
              </div>
              <img src={header} alt="Mae's Cookbook Header" />
            </div>
            <div className="group">
              <WorkCard
                title="PRELUDE"
                description="Food, as a form of art is unique in many ways. Just like painting appeals to our sense of sight, and music appeals to our sense of hearing, food, when created at its best, appeals to all five senses at once. Textures, flavors, colors, and aromas of ingredients used greatly influences the overall taste of a meal.” For some, cooking is an highly enjoyable task while for some others like myself, it’s a tiring chore. However, when we think of cooking as an art practice or project than a chore it becomes a much more enjoyable process. To eliminate one of the factors that contribute to us seeing cooking as a chore, I designed a mobile application that walks you through the process of cooking a meal successfully and provides an opportunity for users to share their creative work of art."
              />
              <WorkCard
                title="INTRODUCTION"
                description="The creation of Mae’s cookbook is fueled by the desire to provide a database of recipes where users can have a directory of their favorite meals. To make the UX more extended, it allows users to search for its recipes by the supplies they have, preferred cuisine types, occasion and more
            The application layout is structured around intuitive navigation, high readability and eye catching background. However, it is enough to give users a product that solves their problem but much more better when you give users product that solves their problems according to their specific needs"
              />
              <img src={second} alt="Second Mae Cookbook" />
            </div>
            <div className="group">
              <WorkCard
                title="HOW DOES MAE’S COOKBOOK HELP?"
                description="Food, as a form of art is unique in many ways. Just like painting appeals to our sense of sight, and music appeals to our sense of hearing, food, when created at its best, appeals to all five senses at once. Textures, flavors, colors, and aromas of ingredients used greatly influences the overall taste of a meal.” For some, cooking is an highly enjoyable task while for some others like myself, it’s a tiring chore. However, when we think of cooking as an art practice or project than a chore it becomes a much more enjoyable process. To eliminate one of the factors that contribute to us seeing cooking as a chore, I designed a mobile application that walks you through the process of cooking a meal successfully and provides an opportunity for users to share their creative work of art."
              />
              <div className="work__card">
                <div className="work__card__left">
                  <h6>USERS: MAPPING PRIORITIES AND NEEDS</h6>
                </div>
                <div>
                  <div className="work__card__right">
                    <p>
                      Initially, I conducted interviews asked participants,
                      "What if you had someone to walk you through the process
                      of cooking your desired meal every time?" and "What
                      benefits will a food recipe app bring to you?".
                    </p>
                  </div>
                  <img src={persona} alt="Second Mae Cookbook" />
                  <div className="work__card__right">
                    <p>Through this, I arrived at the following:</p>
                    <ul>
                      <li>
                        For people who have little or no understanding about
                        cooking, providing recipes at their fingertips is a way
                        to alleviate difficulty and ensure they cook their
                        desired meal successfully
                      </li>
                      <li>
                        People want to enjoy cooking because for some, it serves
                        as a form of relaxation
                      </li>
                      <li>
                        People are anxious and worried when they find recipes
                        for their desired meal but the ingredients does not meet
                        dietary requirements
                      </li>
                      <li>
                        People feel nourished when they are part of a community
                        that provides insights into how to nourish the body of
                        others
                      </li>
                    </ul>
                  </div>
                  <div className="group">
                    <h6>
                      FROM HERE I REFRAMED MY CHALLENGE INTO THIS QUESTION
                    </h6>
                    <p className="different">
                      “How can I provide the required information and walk
                      Sandra, our faux persona, through the process of cooking
                      her desired meal and help her feel confident about her
                      journey as a chef while simultaneously enhancing her
                      mental state”
                    </p>
                  </div>
                  <div className="work__card__right">
                    <p>
                      Understanding the users, their desires, and preferences is
                      a step closer to designing important screens, crafting the
                      perfect delivery process, and solving the outlined process
                      within the app. I was able to categorize insights that
                      stood out to focus on them and turn them into design
                      opportunities with an Affinity Diagram.
                    </p>
                  </div>
                  <img src={empathymap} alt="Empathy Map Mae Cookbook" />
                  <div className="work__card__right">
                    <p>
                      From the diagram above, I grouped “Potential features”
                      which are crucial to users into two; high and medium
                      value.
                    </p>
                  </div>
                  <img src={grouping} alt="Grouping Mae Cookbook" />
                </div>
              </div>
              <div className="work__card">
                <div className="work__card__left">
                  <h6>USER FLOW MAPPING</h6>
                </div>
                <div>
                  <div className="work__card__right">
                    <p>
                      The next step was to sketch out the flow of the main
                      features.
                    </p>
                  </div>
                  <img src={userflow} alt="Second Mae Cookbook" />
                </div>
              </div>
              <WorkCard
                title="CAN I KNOW WHAT WORKS FOR YOU?"
                description="To be able to customize the app experience for each user, I prioritized onboarding as a must-have feature. I looked at the onboarding process of comparators like Tasty and decided that I wanted Sandra, or any user, to feel excited and relaxed about cooking their meal. Having the users getting g anxious and asking questions about their desired meal fitting into their health needs wasn’t an option. A two-part questionnaire screen was designed to understand the specific dietary requirement which in turn, would be used to determine and customize their experience."
              />
              <img src={third} alt="Third Mae Cookbook" />
              <WorkCard
                title="VIEW PERSONALIZED RECIPES"
                description="Where users can explore recipes recommended to them based on the information provided during the onboarding process. On this screen, there are numerous options from which a user can choose. It navigates to another screen where the user can view the table of information on the page; Instruction, Utensils, Videos, and Ingredients. Cooking time, difficulty level suggested accompaniments, tips, and measurement of ingredients per serving are provided on the “Ingredients” section. To make the UX more extended, I added an audio reading option to relieve users of the stress of holding a phone and cooking simultaneously. Easy peasy."
              />
              <img src={four} alt="Third Mae Cookbook" />
              <div className="work__card">
                <div className="work__card__left"></div>
                <div>
                  <div className="work__card__right">
                    <p>Hierarchy of Data Points:</p>
                    <ul>
                      <li>
                        Primary: Food photo name, ingredients, utensils,
                        measurement, instructions, and videos.
                      </li>
                      <li>
                        Secondary: Cooking time, serving, difficulty level,
                        suggested accompaniment, and tips.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <WorkCard
                title="WHAT’S IN YOUR KITCHEN?"
                description="Based on the insights I gained during the research stage, it wasn’t enough, for users to search for the name of the recipe alone. Other options available to users are Ingredients, Cuisine Type, Diet, Occasion, Season, and Difficulty."
              />
              <img src={six} alt="Five Mae Cookbook" />
              <WorkCard
                title="WHAT’S IN YOUR KITCHEN?"
                description="Users can create a directory of their favorite recipes and group them into categories"
              />
              <img src={seven} alt="Six Mae Cookbook" />
              <WorkCard
                title="Profile"
                description="This screen houses all the customized information and preference of uses from the onboarding process. On the top of this screen is a section for users to share their recipes. It navigates to another screen that compiles necessary data about the meal and the cooking process."
              />
              <img src={eight} alt="Eight Mae Cookbook" />
              <WorkCard
                title="SHARE"
                description="This feature allows you to be creators. Users can fill in the required information about the meal, add a photo of the meal, and post."
              />
              <img src={nine} alt="Nine Mae Cookbook" />
            </div>
          </div>
        </Container>
        <Footer />
      </main>
    );
  }
}
