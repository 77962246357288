import React from 'react'

export default function WorkCard({ title, description }) {
  return (
    <div className="work__card">
      <div className="work__card__left">
        <h6>{title}</h6>
      </div>
      <div className="work__card__right">
        <p>{description}</p>
      </div>
    </div>
  )
}
